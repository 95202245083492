// フォーム

<template lang="pug">
.survey-form

  el-form(
    v-if='ready',
    label-position='top',
    :model='form',
    :rules='rules',
    ref='form',
    hide-required-asterisk
  )

    template(
      v-for='item in items'
    )

      //- 同意チェックボックス
      div.agreement(
        v-if='item.type === "agreement"',
        :class='{ checked: !!agreements[item.id] }'
      )
        pre {{ item.caption }}
        el-checkbox-group.checkbox(
          v-model='agreements[item.id]'
        )
          el-checkbox(
            :label='true'
          ) 同意する

      //- 説明文
      pre.message(
        v-else-if='item.type === "message"',
        :class='{ "another-style": item.isAnotherStyle }'
      ) {{ item.caption }}

      //- 見出し
      h3.heading(
        v-else-if='item.type === "heading"'
      ) {{ item.caption }}

      //- 静的な要素でない(=編集可能)
      el-form-item(
        v-else,
        :label='item.caption',
        :prop='item.id'
      )
        template(slot='label')
          span.label {{ item.caption }}
          el-tag.tag(
            v-if='item.required',
            size='mini',
            type='danger'
          ) 必須
          el-tag.tag(
            v-if='item.validator || ["number", "table", "matrix"].indexOf(item.type) !== -1',
            size='mini'
          ) 半角
          el-tag.tag(
            v-if='item.unique',
            size='mini'
          ) 重複不可

        el-input(
          v-if='item.type === "line"',
          v-model.trim='form[item.id]'
        )

        el-input(
          v-else-if='item.type === "number"',
          type='tel',
          :value='form[item.id]',
          @input='form[item.id] = $event',
          @blur='form[item.id] = parseFloat($event.target.value) || 0'
        )

        el-input(
          v-else-if='item.type === "multiline"',
          type='textarea',
          v-model='form[item.id]'
        )

        el-select(
          v-else-if='item.type === "single"',
          v-model='form[item.id]'
        )
          el-option(
            v-for='value in item.options || []',
            :key='value',
            :label='value',
            :value='value'
          )

        el-checkbox-group(
          v-else-if='item.type === "multiple"',
          v-model='form[item.id]'
        )
          el-checkbox(
            v-for='value in item.options || []',
            :key='value',
            :label='value'
          )

        div.name-input(
          v-else-if='item.type === "name"'
        )
          el-row(:gutter='20')
            el-col(:xs='12', :sm='10', :md='6', :lg='6')
              el-input(placeholder='姓', v-model.trim='form[item.id][0]')
            el-col(:xs='12', :sm='10', :md='6', :lg='6')
              el-input(placeholder='名', v-model.trim='form[item.id][1]')

        div.kana-input(
          v-else-if='item.type === "kana"'
        )
          el-row(:gutter='20')
            el-col(:xs='12', :sm='10', :md='6', :lg='6')
              el-input(placeholder='せい', v-model.trim='form[item.id][0]')
            el-col(:xs='12', :sm='10', :md='6', :lg='6')
              el-input(placeholder='めい', v-model.trim='form[item.id][1]')

        div.table-input(
          v-else-if='item.type === "table"'
        )
          table
            thead
              tr
                th(
                  v-for='value in item.options || []'
                ) {{ value }}
            tbody
              tr
                td(
                  v-for='option, i in item.options || []'
                )
                  el-input(
                    type='tel',
                    :value='form[item.id][i]',
                    @input='form[item.id].splice(i, 1, $event)',
                    @blur='form[item.id].splice(i, 1, parseFloat($event.target.value) || 0)'
                  )

        div.matrix-input(
          v-else-if='item.type === "matrix"'
        )
          table
            thead
              tr
                th
                th(
                  v-for='col in item.options'
                ) {{ col }}
            tbody
              tr(
                v-for='row, i in item.rows'
              )
                th {{ row }}
                td(
                  v-for='j in item.options.length'
                )
                  el-input(
                    type='tel',
                    :value='form[item.id][item.options.length * i + j - 1]',
                    @input='form[item.id].splice(item.options.length * i + j - 1, 1, $event)',
                    @blur='form[item.id].splice(item.options.length * i + j - 1, 1, parseFloat($event.target.value) || 0)'
                  )

        div.org-input(
          v-else-if='item.type === "org"'
        )
          el-row.row(:gutter='20')
            el-col(:xs='12', :sm='10', :md='6', :lg='6')
              el-select.select(
                placeholder='地区を選択',
                v-model='wardId'
              )
                el-option(
                  v-for='item in wardList',
                  :key='item.value',
                  :label='item.name',
                  :value='item.value'
                )
            el-col(:xs='12', :sm='10', :md='6', :lg='6')
              el-form-item(
                prop='organizationId'
              )
                el-select.select(
                  placeholder='園名を選択',
                  v-model='form[item.id]'
                )
                  el-option(
                    v-for='item in organizationList',
                    :key='item.id',
                    :label='item.name'
                    :value='item.name'
                  )

        //- 備考
        p.note(v-if='item.note') {{ item.note }}

</template>

<script>
import uniqBy from 'lodash.uniqby'
import uniq from 'lodash.uniq'

import getOrganizationListApi from '@/api/get-organization-list'

const validators = {
  mail: { type: 'email', message: 'メールアドレスが正しくありません。', trigger: 'none' },
  tel: {
    type: 'string',
    pattern: /^\d{2,5}-?\d{1,4}-?\d{4}$/,
    message: '電話番号が正しくありません。',
    trigger: 'none',
  },
  hankaku: {
    type: 'string',
    pattern: /^[0-9a-zA-Z]+$/,
    message: '半角英数字で入力してください。',
    trigger: 'none',
  },
}

export default {
  name: 'SurveyForm',

  props: {
    items: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Object,
    },
  },

  data() {
    return {
      form: {},
      agreements: {},
      rules: {},
      wardId: null,
      // 団体のリスト
      organizations: [],
      ready: false,
    }
  },

  computed: {
    wardList() {
      return uniqBy(
        this.organizations.map((item) => {
          return {
            name: item.regionName,
            value: item.regionId,
          }
        }),
        'value'
      )
    },
    // 地区を選択した場合は、その地区で絞り込まれる
    organizationList() {
      if (this.wardId) {
        return this.organizations.filter((item) => item.regionId === this.wardId)
      } else {
        return this.organizations
      }
    },
  },

  async created() {
    const response = await getOrganizationListApi()
    if (!response.ok) return
    this.organizations = response.payload.items
    const form = {}
    const rules = {}
    const agreements = {}
    for (const item of this.items) {
      if (!item.id) continue
      // 同意ボタンは別個の扱い。また、複数ある場合を考慮
      if (item.type === 'agreement') {
        agreements[item.id] = false
        continue
      }
      const formData = this.createFormData(item)
      if (formData !== null) form[item.id] = formData
      // validator
      const validator = this.createValidator(item)
      if (validator) rules[item.id] = validator
    }
    // 初期値が渡されていれば、マージする
    this.form = this.value ? Object.assign(form, this.value) : form
    this.agreements = agreements
    this.rules = rules
    this.ready = true
  },

  methods: {
    blurNumInput() {},
    // そのアイテムにあった要素を作成
    createFormData(item) {
      if (item.type === 'message' || item.type === 'heading') return null
      // なまえは要素が２つ
      else if (item.type === 'name' || item.type === 'kana') return ['', '']
      else if (item.type === 'multiple') return []
      else if (item.type === 'table') {
        if (!item.options) return []
        const array = []
        for (let i = 0; i < item.options.length; ++i) array[i] = 0
        return array
      } else if (item.type === 'matrix') {
        if (!item.options || !item.rows) return []
        const array = []
        for (let i = 0; i < item.options.length * item.rows.length; ++i) array[i] = 0
        return array
      }
      return ''
    },
    // そのアイテムにあったバリデータを設定
    createValidator(item) {
      const validator = []
      if (
        !item.id ||
        item.type === 'message' ||
        item.type === 'heading' ||
        item.type === 'agreement'
      ) {
        return null
      } else if (item.type === 'name') {
        // 名前
        if (item.required) {
          validator.push({
            type: 'array',
            validator: (rule, value, cbk) => {
              if (!value[0] || !value[1]) cbk('必須項目です。')
              else cbk()
            },
            trigger: 'none',
          })
        }
      } else if (item.type === 'kana') {
        // ふりがな
        if (item.required) {
          validator.push({
            type: 'array',
            validator: (rule, value, cbk) => {
              if (!value[0] || !value[1]) cbk('必須項目です。')
              else cbk()
            },
            trigger: 'none',
          })
        }
        // ひらがなチェック
        validator.push({
          type: 'array',
          validator: (rule, value, cbk) => {
            if (!/^[ぁ-ゞー～]*$/.test(value[0]) || !/^[ぁ-ゞー～]*$/.test(value[1])) {
              cbk('ひらがなで入力してください。')
            } else {
              cbk()
            }
          },
          trigger: 'none',
        })
      } else if (item.type === 'multiple') {
        // 複数選択
        if (item.required) {
          validator.push({
            type: 'array',
            validator: (rule, value, cbk) => {
              if (!value || value.length === 0) cbk('最低1つは選択してください。')
              else cbk()
            },
            trigger: 'none',
          })
        }
      } else if (item.type === 'table' || item.type === 'matrix') {
        if (item.unique) {
          validator.push({
            type: 'array',
            validator: (rule, value, cbk) => {
              if (value.length !== uniq(value).length) cbk('同じ値が複数あります。')
              else cbk()
            },
            trigger: 'none',
          })
        }
      } else if (item.type === 'org') {
        if (item.required) {
          validator.push({ required: true, message: '必須項目です。', trigger: 'none' })
        }
      } else {
        if (item.required) {
          validator.push({ required: true, message: '必須項目です。', trigger: 'none' })
        }
        if (item.validator && validators[item.validator]) {
          validator.push(validators[item.validator])
        }
      }

      return validator.length ? validator : null
    },

    validate() {
      return this.$refs.form.validate()
    },
  },

  watch: {
    form: {
      handler() {
        this.$emit('input', this.form)
      },
      deep: true,
    },
    agreements: {
      handler() {
        let result = true
        const keys = Object.keys(this.agreements)
        if (keys.length) {
          for (const key of keys) {
            if (!this.agreements[key]) {
              result = false
              break
            }
          }
        }
        this.$emit('update:agreement', result)
      },
      deep: true,
    },
  },
}
</script>

<style lang="sass">
.survey-form
  .el-form
    .el-form-item__label
      .label
        color: $grey-dark
        font-weight: bold
        padding-bottom: 0
      .tag
        margin-left: 0.5rem

    .table-input, .matrix-input
      .el-input
        input
          padding: 0 0.5rem
          border: none
          text-align: center
</style>

<style lang="sass" scoped>
.survey-form
  .table-input, .matrix-input
    max-width: 100%
    overflow-x: auto
    padding-bottom: 0.2rem
    table
      border-collapse: collapse
      th, td
        border: 1px solid #aaa
      th
        line-height: 1
        color: $grey-dark
        padding: 0.5rem 0.2rem
        font-weight: normal
        background: #ddd
        white-space: nowrap
      tbody
        th
          padding: 0.5rem 1rem
        td
          text-align: center
          > div
            width: 100%
            min-width: 4rem
  .org-input
    .select
      width: 100%
  p.note
    color: darken($color-1, 20%)
    line-height: 1.2
    margin-top: 0.5rem
    font-size: 0.85rem
  .message
    font-size: 0.9rem
    line-height: 1.4
    background: lighten($color-1, 40%)
    padding: 0.8rem
    border-radius: 3px
    margin: 1.5rem 0
    color: $grey-darker
    &.another-style
      background: transparent
      padding: 0.2rem
      margin: 1rem 0
  .agreement
    font-size: 0.9rem
    line-height: 1.4
    margin: 1.5rem auto
    border: 2px solid #ccc
    border-radius: 3px
    padding: 0.8rem
    color: $grey-darker
    background: $white-ter
    &.checked
      background: #ffffe3
    .checkbox
      margin-top: 0.8rem
      text-align: center
  .heading
    border-left: 5px solid $color-1
    background-color: $white-ter
    color: $grey-darker
    font-size: 1.1rem
    letter-spacing: 1px
    padding: 0.3rem 0.7rem
    margin: 1.5rem 0 1rem
</style>
